import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          {/* <div className="flex-wrap d-flex justify-content-center justify-content-md-center justify-content-lg-between align-items-top">
            <div
              className="d-none d-md-none d-lg-block wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="heading">Learn About AWSMO</div>
              <div className="d-flex flex-column justify-content-start align-items-start">
                <Link to="" className="link">
                  Getting Started
                </Link>
                <Link to="" className="link">
                  Training and Certification
                </Link>
                <Link to="" className="link">
                  AWSMO Solutions Portfolio
                </Link>
                <Link to="" className="link">
                  Architecture Center
                </Link>
                <Link to="" className="link">
                  Product and Technical FAQs
                </Link>
                <Link to="" className="link">
                  Analyst Reports
                </Link>
              </div>
            </div>
            <div
              className="d-none d-md-none d-lg-block wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="heading">Resources</div>
              <div className="d-flex flex-column justify-content-start align-items-start">
                <Link to="" className="link">
                  Getting Started
                </Link>
                <Link to="" className="link">
                  Training and Certification
                </Link>
                <Link to="" className="link">
                  AWSMO Solutions Portfolio
                </Link>
                <Link to="" className="link">
                  Architecture Center
                </Link>
                <Link to="" className="link">
                  Product and Technical FAQs
                </Link>
                <Link to="" className="link">
                  Analyst Reports
                </Link>
              </div>
            </div>
            <div
              className="d-none d-md-none d-lg-block wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="heading">Developers on AWSMO</div>
              <div className="d-flex flex-column justify-content-start align-items-start">
                <Link to="" className="link">
                  Getting Started
                </Link>
                <Link to="" className="link">
                  Training and Certification
                </Link>
                <Link to="" className="link">
                  AWSMO Solutions Portfolio
                </Link>
                <Link to="" className="link">
                  Architecture Center
                </Link>
                <Link to="" className="link">
                  Product and Technical FAQs
                </Link>
                <Link to="" className="link">
                  Analyst Reports
                </Link>
              </div>
            </div>
            <div
              className="d-none d-md-none d-lg-block wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="heading">Help</div>
              <div className="d-flex flex-column justify-content-start align-items-start">
                <Link to="" className="link">
                  Getting Started
                </Link>
                <Link to="" className="link">
                  Training and Certification
                </Link>
                <Link to="" className="link">
                  AWSMO Solutions Portfolio
                </Link>
                <Link to="" className="link">
                  Architecture Center
                </Link>
                <Link to="" className="link">
                  Product and Technical FAQs
                </Link>
                <Link to="" className="link">
                  Analyst Reports
                </Link>
              </div>
            </div>
            <div
              className="d-flex flex-lg-column social-icon wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="wrap-t">
                <Link to="">
                  <i className="bi bi-twitter"></i>
                </Link>
              </div>
              <div className="wrap-in">
                <Link to="">
                  <i className="bi bi-linkedin"></i>
                </Link>
              </div>
              <div className="wrap-f">
                <Link to="" className="f-link">
                  <i className="bi bi-facebook"></i>
                </Link>
              </div>
              <div className="wrap-u">
                <Link to="">
                  <i className="bi bi-youtube"></i>
                </Link>
              </div>
            </div>
          </div> */}
          {/* <hr className="line" /> */}
          <div
            className="flex-wrap d-flex justify-content-center align-items-center footer-btm wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div>
              <Link to="/" className="link">
                Home
              </Link>
            </div>
            <div>
              <span>|</span>
              <Link to="/legal" className="link">
                Legal
              </Link>
            </div>
            <div>
              <span>|</span> © 2023, All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
