import React, { useEffect } from "react";
import VideoSection from "./VideoSection";
import Aboutus from "./Website/Aboutus";
import Legal from "./Legal";
import { Route, Routes } from "react-router-dom";
import "./Website/website.css";

function App() {
  useEffect(() => {
    document.title = "AWSMO";
  }, []);

  return (
    <Routes>
      <Route path="/" element={<VideoSection />} />
      <Route path="/aboutus" element={<Aboutus />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/website" element={<Aboutus />} />
    </Routes>
  );
}

export default App;
