import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import BannerAbout from "../img/about_us_banner.png";

const Aboutus = () => {
  return (
    <div style={{ userSelect: "none" }}>
      <Header />
      <section className="m-slider">
        <img src={BannerAbout} alt="about us AWSMO" className="img-fluid" />
        <div className="container">
          <div className="banner-wrap-txt1 wow zoomIn" data-wow-delay="0.3s">
            About Us
          </div>
        </div>
      </section>
      <section className="sec-about">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div
                className="d-flex flex-column justify-content-start wow fadeInUp"
                data-wow-delay="0.5s"
              >
                {/* <div className="sub-heading">A SUCCESS STORY </div> */}
                <div className="headihg-main mb-4">Who Are We</div>
              </div>
              <p className="wow fadeInUp" data-wow-delay="0.5s">
                Great things happen when technologies and economics come
                together. We are a team of engineers with extensive backgrounds
                in Artificial Intelligence, DevOps, Automation, Product
                Management, and Cloud Costs. We saw companies struggle with
                implementing strategies for reducing wastage of their cloud
                dollars. We always wondered if technology and automation can
                achieve this goal. Then we built Awsmo - a tool that
                automatically optimizes your cloud using the power of AI.
              </p>
            </div>
            <div className="col-md-4  wow fadeInRight" data-wow-delay="0.5s">
              <div className="wrap-our-values">
                <div className="heading">Our Mission</div>
                <p>
                  Our goal is that every cloud user should get the most out of
                  their spend on cloud infrastructure.
                </p>
                <p>
                  {" "}
                  We are laser focused on reducing wastage of resources using AI
                  and Automation.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Aboutus;
