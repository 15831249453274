const initialState = [];

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      state = [...state, action.payload];
      return state;

    case "LOGOUT":
      state = [];
      return state;

    default:
      return state;
  }
};

export default loginReducer;
