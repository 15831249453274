import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div>
          <Link className="ml-md-2 ml-lg-2 mr-lg-1" to="/">
            Home
          </Link>{" "}
          |
          {/* <Link className="ml-md-2 ml-lg-2 mr-lg-1" to="/legal">
            Legal Notice
          </Link>{" "}
          | */}
          <Link className="ml-md-2 ml-lg-2 mr-lg-1" to="/website">
            Website
          </Link>{" "}
          | &copy; Copyright 2023 awsmo.ai. All rights reserved.
          {/* |
          <a
            className="ml-lg-1"
            target="_blank"
            rel="noreferrer"
            href="mailto:support@awsmo.ai"
          >
            Contact Us
          </a> */}
        </div>
      </footer>
    </>
  );
};

export default Footer;
