import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import AWSVideo from "./video/AWS_3_1.mp4";

const VideoSection = () => {
  return (
    <>
      <Header />
      <section className="header-margin">
        <div className="bg-grey">
          <div className="video-wrap pl-md-4 pr-md-4 pl-2 pr-2">
            <video controls autoPlay loop muted className="videowrapcontent">
              <source
                width="100%"
                height="100%"
                src={AWSVideo}
                type="video/mp4"
              ></source>
            </video>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default VideoSection;
