import React from "react";
import Logo from "./img/logo.png";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <>
      <header className="mb-3">
        <nav className="fixed-header">
          <div className="d-flex justify-content-center align-items-center p-2">
            <Link to={"/aboutus"}>
              <img src={Logo} className="img-fluid" alt="test" />
            </Link>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
