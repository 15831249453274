import React, { useEffect } from "react";
import Logo from "../img/logo.png";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { Navbar, Container, Nav } from "react-bootstrap";
import "./animate/animate.min.css";
import WOW from "wowjs";

const Header = () => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <>
      <ScrollToTop
        smooth
        color="#F9AF27"
        style={{
          borderRadius: "12px",
        }}
      />
      <header>
        <div className="content">
          <Navbar
            collapseOnSelect
            expand="xl"
            bg="dark"
            variant="dark"
            fixed="top"
          >
            <Container>
              <Navbar.Brand>
                <Link to="/">
                  <img src={Logo} className="img-logo" alt="logo" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto ml-0 ml-mb-none ml-lg-4"></Nav>
                <Nav className="header-btnWrap">
                  {/* <Link className="nav-link btn-sign mr-lg-3" to="/contact">
                    Contact Us
                  </Link> */}
                  <Link
                    className="nav-link btn-account"
                    to="mailto:support@awsmo.ai"
                  >
                    Contact Us
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </header>
    </>
  );
};

export default Header;
